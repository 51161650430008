import React, { useState, lazy, Suspense, useEffect } from "react";
import "@fontsource/poppins";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import axios from "axios";
import { format, parseISO } from "date-fns";
import CacheBuster from "react-cache-buster";
import appversion from "../package.json";


const Header = lazy(() => import(".././src/Componentss/Home/Header"));
const Banner = lazy(() => import("./Componentss/Home/Banner"));
const Rate = lazy(() => import("./Componentss/Home/Rate"));
const VaForm = lazy(() => import("./Componentss/Home/VaForm"));
const Highlights = lazy(() => import("./Componentss/Home/Highlights"));
const FooternavMenu = lazy(() => import("./Componentss/Home/FooterNavMenu"));
const Footer = lazy(() => import("./Componentss/Home/CustomFooter"));
const WhyVALoan = lazy(() => import("./Componentss/WhyVALoan/index"));
const Eligibility = lazy(() => import("./Componentss/Eligibility/index"));
const Purchase = lazy(() => import("./Componentss/Purchase/index"));
const Refinance = lazy(() => import("./Componentss/Refinance/index"));
const VAFundingFee = lazy(() => import("./Componentss/VAFundingFee/index"));
const ApplicationProcess = lazy(() => import("./Componentss/ApplicationProcess/index"));
const ResponsibleHomeownership = lazy(() => import("./Componentss/ResponsibleHomeownership/index"));
const Commonquestions = lazy(() => import("./Componentss/CommonQuestions/index"));
const VAIRRRL = lazy(() => import("./Componentss/VAIRRRL/index"));
const Aboutus = lazy(() => import("./Componentss/AboutUs/index"));
const Sitemap = lazy(() => import("./Componentss/Sitemap/index"));

function Home() {

  const imgCDNURL = process.env.REACT_APP_GETVAHOMELOAN_CDN_URL;
  let data = require('./va-home-loan-content.json'); // This is temporary fetching data from JSON

  // Below variable will be used to store data which is arriving from json

  const [vaHomeLoanData, setvaHomeLoanData] = useState();
  const [headerComponentData, setheaderComponentData] = useState();
  const [bannerComponentData, setbannerComponentData] = useState();
  const [ratesComponentData, setratesComponentData] = useState();
  const [vaFormComponentData, setvaFormComponentData] = useState();
  const [highlightsComponentData, sethighlightsComponentData] = useState();
  const [footerNavbarComponentData, setfooterNavbarComponentData] = useState();

  // Below code is used for fetching data from json file ****************************************

  useEffect(() => {
    const fetchVaHomeLoanContentJSON = async () => {
      await axios.get(process.env.REACT_APP_GETVAHOMELOANJSON_CDN_URL)
        .then(response => {
          const vaHomeLoanJsonData = data; //response.data;
          setvaHomeLoanData(vaHomeLoanJsonData);
          setheaderComponentData(vaHomeLoanJsonData.headerComponent);
          sethighlightsComponentData(vaHomeLoanJsonData.HighlightComponent);
          setfooterNavbarComponentData(vaHomeLoanJsonData.footerNavbar);
          setratesComponentData(vaHomeLoanJsonData.ratesComponent);
          setvaFormComponentData(vaHomeLoanJsonData.vaFormComponent);
          setbannerComponentData(vaHomeLoanJsonData.bannerComponent);
        })
        .catch(error => {
          console.log(error);
        });
    };
    fetchVaHomeLoanContentJSON();
  }, []);
  // End ********************************************************************************************

  // Start of code to fetch rates

  const [themeId, setThemeId] = useState("getvahomeloan");
  const [configJSON, setConfigJSON] = useState("");
  const [va15, setVA15] = useState("");
  const [va30, setVA30] = useState("");
  const [pricingAsOfDate, setPricingAsOfDate] = useState("");

  async function locationError(error) {
    // Get the default price scenario
    await getPrice();
  }

  /**
   * @description Get the pricing config file details
   * 
   */
  const getConfigData = async () => {
    try {
      // Get the getvahomeloan-config.json file from S3 bucket
      const configData = await axios(
        `${process.env.REACT_APP_RATE_PRODUCT}/getvahomeloan-config.json`
      );

      //console.log(JSON.stringify(configData.data[0]));
      if (configData.data[1].VA_30_YR_FIXED) {
        setConfigJSON(configData.data[1].VA_30_YR_FIXED);
      }
    } catch (error) {
      console.error("Unable to load getvahomeloan-config.json file from S3 bucket: ", error);
    }
  };

  /**
   * @description Get the static pricing data from s3.
   * 
   */
  const getPrice = async (userStateCode) => {
    try {
      // Get the static data from s3 for the filtered state else return the default data
      // Get the state list
      const stateList = await axios(
        `${process.env.REACT_APP_RATE_PRODUCT}/state.json`
      );
      // Filter the state which has the county and zipcode
      const propertyStateData = stateList.data.find(
        (d) => d.state.code === userStateCode
      );
      //console.log("propertyStateData: " + JSON.stringify(propertyStateData));
      let response;
      if (propertyStateData) {
        const county = propertyStateData.state.county.code;
        const zipcode = propertyStateData.state.county.zipcode;
        // console.log("county: " + county);
        // console.log("zipcode: " + zipcode);
        // console.log("themeId: " + themeId);
        response = await axios(
          `${process.env.REACT_APP_RATE_PRODUCT
          }/${themeId}/${userStateCode.toLowerCase()}/${county}/${zipcode}/products.json`
        ).catch((error) => {
          console.error("Unable to pull pricing for user state: ", error);
          locationError();
        });
      } else {
        response = await axios(
          `${process.env.REACT_APP_RATE_PRODUCT}/${themeId}/default.json`
        );
      }
      // console.log("response: " + JSON.stringify(response.data));
      const parseValue = parseISO(response.data.createdAt);
      const dateTime = format(parseValue, "MM/dd/yyyy hh:mm a");
      setPricingAsOfDate(dateTime);

      if (response.data.VA_15_YR_FIXED) {
        setVA15(response.data.VA_15_YR_FIXED);
      }

      if (response.data.VA_30_YR_FIXED) {
        setVA30(response.data.VA_30_YR_FIXED);
      }
    } catch (error) {
      console.error("Unable to load todays rate: ", error);
    }
  };
  useEffect(() => {
    let userIP = "";
    let userZipcode = "";
    let userState = "";

    const fetchData = async () => {
      await axios
        .get("https://api.ipify.org", { timeout: 5000 })
        .then((response) => {
          userIP = response.data;
          getConfigData();
          //console.log("userIP: " + userIP);
          if (userIP) {
            axios
              .post(
                process.env.REACT_APP_MAXMIND,
                { ip: userIP },
                {
                  headers: { "Content-Type": "application/json" },
                },
                { timeout: 5000 }
              )
              .then((response) => {
                //console.log("response.data: " + JSON.stringify(response.data));
                if (
                  response.data !== "IP Not Available" &&
                  response.data !== "Invalid Country"
                ) {
                  userZipcode = response.data.zipcode;
                  //console.log("userZipcode: " + userZipcode);
                  if (userZipcode) {
                    axios
                      .get(
                        process.env.REACT_APP_SUNSOFT_RESTSERVER_ZIPCODE + userZipcode,
                        {
                          headers: { "Content-Type": "application/json" },
                        },
                        { timeout: 5000 }
                      )
                      .then((response) => {
                        userState = response.data.state;
                        //console.log("userState: " + userState);
                        // get the price scenario
                        getPrice(userState);
                      })
                      .catch((error) => {
                        console.error("Get ZIPCODE Error: ", error);
                        locationError();
                      });
                  } else {
                    locationError();
                  }
                } else {
                  locationError();
                }
              })
              .catch((error) => {
                console.error("Post MAXMIND Error: ", error);
                locationError(error);
              });
          }
        })
        .catch((error) => {
          console.error("Get ipify Error: ", error);
          locationError(error);
        });
    };

    fetchData();
  }, []);

  useEffect(() => {
    axios
      .post(
        process.env.REACT_APP_SUNSOFT_RESTSERVER_STATE_LIST,
        { domain: "swmc.com", companyCode: "SUNWST000" },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      )
      .then((response) => {
        let validStates = response.data;
        if (validStates) {
          // set localStorage for userSession in RateQuotes for state error checking.
          localStorage.setItem("stateList", JSON.stringify(validStates));
        }
      })
      .catch((error) => {
        console.error('STATE_LIST Error: ', error);
      });
  }, []);

  // End of Rates code

  return (
    <>
      <Suspense fallback={<div />}>
        <Header headerComponentData={headerComponentData}  />
      </Suspense>

      <Suspense fallback={<div />}>
        <Banner bannerComponentData={bannerComponentData} />
      </Suspense>
      <Suspense fallback={<div />}>
        <Rate
          ratesComponentData={ratesComponentData}
          configJSON={configJSON}
          va15={va15}
          va30={va30}
          pricingAsOfDate={pricingAsOfDate}
          vaFormComponentData={vaFormComponentData}
        />
      </Suspense>
      {/* <Suspense fallback={<div />}> 
  <VaForm/>
  </Suspense> */}
      <Suspense fallback={<div />}>
        <Highlights highlightsComponentData={highlightsComponentData} />
      </Suspense>
      <Suspense fallback={<div />}>
        <FooternavMenu footerNavbarComponentData={footerNavbarComponentData} />
      </Suspense>
      <Suspense fallback={<div />}>
        <Footer />
      </Suspense>
    </>
  );
}



function App() {
  const appVersion = appversion.version;
  const isProduction = process.env.NODE_ENV === "production";

  return (

    <CacheBuster
      currentVersion={appversion.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      // loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >

      <BrowserRouter>
        <Suspense fallback={<div className="text-center"></div>}>
          <Routes>
            <Route exact path="/why-va-loan" element={<WhyVALoan />} />
            <Route exact path="/purchase" element={<Purchase />} />
            <Route exact path="/eligibility" element={<Eligibility />} />
            <Route exact path="/refinance" element={<Refinance />} />
            <Route exact path="/VA-Funding-Fee" element={<VAFundingFee />} />
            <Route exact path="/application-process" element={<ApplicationProcess />} />
            <Route exact path="/responsible-homeownership" element={<ResponsibleHomeownership />} />
            <Route exact path="/common-questions" element={<Commonquestions />} />
            <Route exact path="/vaIrrl-skipping2pay" element={<VAIRRRL />} />
            <Route exact path="/about-us" element={<Aboutus />} />
            <Route exact path="/sitemap" element={<Sitemap />} />
            <Route exact path="/" element={<Home />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </CacheBuster>
  );
}

export default App;
